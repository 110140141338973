import axios from 'axios'
import { sha256 } from 'js-sha256'
export interface LptagService {
  postPageInfo(params: any): Promise<void>
  getBotDetails(convId: string): Promise<any>
}

export class FirebaseLptagService implements LptagService {
  async postPageInfo (params: any): Promise<void> {
    try {
      const response = await axios
        .post<unknown>('https://va.bc-intg.liveperson.net/thirdparty-services-0.1/webview', params, {
          headers: {
            Authorization: sha256(`${params?.conversationId} || ${params?.botId}`),
            'Content-type': 'application/json'
          }
        })
    } catch (error: any) {
      console.error('Failed to fetch', error)
    }
  }

  async getBotDetails (convId: string): Promise<any> {
    try {
      const response = await axios
        .get(`https://z1.context.liveperson.net/v1/account/6865765/BellaHealth/${convId}/properties`, {
          headers: {
            'Content-Type': 'application/json',
            'maven-api-key': 'WC2SfjL2U0Njg2NTc2NQ=='
          }
        })

      return response.data
    } catch (error: any) {
      console.error('Failed to fetch', error)
      return []
    }
  }
}

export const instance = new FirebaseLptagService()

export default instance
