































import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import HighlightText from '@/components/HighlightText.vue'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ChatButtonProxy from '@/components/ChatButtonProxy.vue'
import lptagService from '@/services/lptag-service'

@Component({
  components: {
    ContentArea,
    Title,
    PageLayout,
    HighlightText,
    ChatButtonProxy
  }
})
export default class Faq extends Vue {
  $refs!: {
    btn: ChatButtonProxy
  }

  private searchText = ''
  private faqData = []
  private faqDataClone = []
  private filteredData = [] as any
  private activeIndex = -1
  private sentData = false
  private messageUsBtnVisibility = true
  private chatInterval:any

  async created (): Promise<void> {
    this.$store.dispatch('faq/getFaqData').then((res:any) => {
      this.faqData = res
      this.faqDataClone = this.faqData
    })
    this.triggerEvent()

    if (!this.isMobile()) {
      this.chatInterval = setInterval(() => {
        this.isChatMinimize()
      }, 1000)
    }
  }

  public triggerEvent (): void {
    const a = setInterval(async () => {
      const convId = localStorage.getItem('convId') as string

      if (!this.sentData && convId !== null && convId !== undefined && convId !== '') {
        clearInterval(a)
        this.sentData = true
        const getBotDetails = await lptagService.getBotDetails(convId)
        console.log('QVUE: API 1 calling', getBotDetails)
        if (getBotDetails?.userId !== undefined && getBotDetails?.botId !== undefined && getBotDetails?.conversationId !== undefined) {
          const sentData = {
            botId: getBotDetails?.botId,
            conversationId: convId,
            userId: getBotDetails?.userId,
            message: 'App initiating FAQ',
            contextVariables: [{ name: 'appProctorStart', value: false }, { name: 'appFAQStart', value: true }, { name: 'appConvId', value: convId }]
          }
          console.log('QVUE: API 2 calling', sentData)
          await lptagService.postPageInfo(sentData)
        }
      }
    }, 1000)
  }

  private mounted (): void {
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private clearSearch (): void {
    this.searchText = ''
    this.filteredData = []

    this.faqData = this.faqDataClone
  }

  private openChat ():void {
    this.$refs.btn.triggerEvent()
  }

  private search (): void {
    this.activeIndex = -1
    const data = [...this.faqData]
    const a:any = []
    data.forEach((el:any) => {
      el.question = el.question.toLowerCase()
      a.push(el)
    })

    if (this.searchText.length > 0) {
      this.filteredData = a.filter((e:any) => e.question.includes(this.searchText.toLowerCase()))
      if (this.filteredData.length > 0) {
        this.filteredData.forEach((item:any) => {
          item.question = item.question.charAt(0).toUpperCase() + item.question.slice(1)
        })
      } else {
        this.filteredData = []
        this.faqData = []
      }
    } else {
      this.clearSearch()
    }
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  private async isChatMinimize () {
    var styleArray = await document.querySelector('div.lp_minimized')
      ?.attributes.getNamedItem('style')?.value.split(';')
    // console.log('display: block ? ', styleArray?.includes(' display: block'))
    // console.log('display: block ?? ', styleArray?.includes(' display: block') ?? false)
    this.messageUsBtnVisibility = styleArray?.includes(' display: block') as boolean ?? false
  }

  private isMobile ():boolean {
    if (window.innerWidth < 768) {
      return true
    }
    return false
  }

  private beforeDestroy () {
    clearInterval(this.chatInterval)
  }
}
